import React from 'react'
import cx from 'classnames'
import styles from './scss/card.scss'
import { renderThemeClass, findMatchingClassName } from '../../utils/Theme'
import Icon from '../Icon/Icon'

const renderCardHeader = (
  title: string,
  subtitle: string | undefined,
  icon: string | undefined
) => {
  return (
    <div className={styles.cardHeader}>
      <h4 className={styles.headerTitle}>
        {icon && <Icon className={cx(styles.headerIcon)}>{icon}</Icon>}
        {title}
      </h4>
      {subtitle && <div className={cx(styles.headerSubtitle)}>{subtitle}</div>}
    </div>
  )
}

type CardProps = {
  id?: string
  title?: string
  subtitle?: string
  icon?: string
  className?: string
  cardContentClassName?: string
  isSummaryStats?: boolean
  children: React.ReactNode
  theme?: string
}

const Card: React.FunctionComponent<CardProps> = ({
  title,
  subtitle,
  icon,
  className,
  cardContentClassName,
  id,
  isSummaryStats,
  children,
  theme,
}: CardProps) => {
  const summaryStatsStyle = isSummaryStats ? styles.isSummaryStats : null
  const cardContentClass = cardContentClassName
    ? findMatchingClassName(cardContentClassName, styles)
    : null

  const themeClass = renderThemeClass(theme, styles)

  return (
    <div className={cx(styles.card, className, themeClass)} id={id}>
      {title && renderCardHeader(title, subtitle, icon)}
      <div
        className={cx(styles.cardContent, summaryStatsStyle, cardContentClass)}
      >
        {children}
      </div>
    </div>
  )
}

export default Card
