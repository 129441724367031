import { capitalize } from 'lodash'

export const Theme = ['dark', 'light']

const isSpecifiedTheme = (theme: string | undefined) => {
  return Theme.find(item => item === theme)
}

export const renderThemeClass = (theme: string | undefined, styles: any) => {
  if (styles) {
    return isSpecifiedTheme(theme) ? styles[`dex${capitalize(theme)}`] : null
  }
}

export const findMatchingClassName = (
  query: string | undefined,
  matcherArray: any
) => {
  if (matcherArray[`${query}`]) {
    return matcherArray[`${query}`]
  } else {
    return query
  }
}
