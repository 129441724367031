import React from 'react'
import { GeneralProps } from '../../utils/TypeDeclaration'
import cx from 'classnames'
import styles from './heading.scss'

export const Heading1: React.FC<GeneralProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <h1 id={id} className={cx(styles.heading1, className)}>
      {children}
    </h1>
  )
}

export const Heading2: React.FC<GeneralProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <h2 id={id} className={cx(styles.heading2, className)}>
      {children}
    </h2>
  )
}

export const Heading3: React.FC<GeneralProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <h3 id={id} className={cx(styles.heading3, className)}>
      {children}
    </h3>
  )
}

export const Heading4: React.FC<GeneralProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <h4 id={id} className={cx(styles.heading4, className)}>
      {children}
    </h4>
  )
}

export const Heading5: React.FC<GeneralProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <h5 id={id} className={cx(styles.heading5, className)}>
      {children}
    </h5>
  )
}

export const Heading6: React.FC<GeneralProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <h6 id={id} className={cx(styles.heading6, className)}>
      {children}
    </h6>
  )
}
