import React from 'react'
import styles from './scss/icon.scss'
import cx from 'classnames'
import { renderThemeClass } from '../../utils/Theme'

type Props = {
  theme?: string
}

const Loader: React.FC<Props> = ({ theme }: Props) => {
  const themeClass = renderThemeClass(theme, styles)
  return (
    <div className={cx(styles.loaderBounce, themeClass)}>
      <div className={cx(styles.loaderBounceDiv, styles.bounce1)}></div>
      <div className={cx(styles.loaderBounceDiv, styles.bounce2)}></div>
      <div className={cx(styles.loaderBounceDiv, styles.bounce3)}></div>
    </div>
  )
}

export default Loader
