import React from 'react'
import cx from 'classnames'
import styles from './scss/icon.scss'

interface IconProps {
  className?: string
  children?: React.ReactNode
}
const Icon = ({ className, children }: IconProps) => {
  return (
    <i className={cx(styles.materialIcon, 'icon', className)}>{children}</i>
  )
}

export default Icon
